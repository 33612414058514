export default {
    mamespaced : true,
    state: { 
        title: "", 
        project : "",
        autor : "", 
        outreach : "",
        paten: "",
        titlePaten : "",
        allsearch :"",
        sdg : "",
        concepts : "",
        school : ""
    },
    mutations: { 
        settitle(state, value) {
            state.title = value 
        },
        setproject(state, value) {
            state.project = value 
        },
        setautor(state, value) {
            state.autor = value 
        },
        setoutreach(state, value) {
            state.outreach = value 
        },
        Setpaten(state, value) {
            state.paten = value 
        }, 
        setsPaten(state, value) {
            state.titlePaten = value 
        },
        setsSDG(state, value) {
            state.sdg = value 
        },
        setcConcepts(state, value) {
            state.concepts = value 
        }, 
        setcSchool(state, value) {
            state.school = value 
        },
    },
    actions: {
   },
   
}
