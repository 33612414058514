<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-landing navbar-light fixed-top" id="navbar">
      <BContainer>
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/images/logo-sm-22.png" class="card-logo card-logo-dark" alt="logo dark" height="67">
          <img src="@/assets/images/logo-sm-11.png" class="card-logo card-logo-light" alt="logo light" height="67">
        </router-link>
        <BButton class="navbar-toggler py-0 fs-20 text-body" type="button" v-b-toggle.navbarSupportedContent>
          <i class="mdi mdi-menu"></i>
        </BButton>
        <BCollapse class="navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">

            <li id="home" class="nav-item" @click="clickMenu('home')">
              <BLink class="nav-link fs-15" to="/">Home</BLink>
            </li>

            <span v-if="access_token != nulll">
              <li class="nav-item" @click="clickMenu('overview')" v-if="access_token != ''">
                <BLink class="nav-link fs-15" to="/overview">MyScholar
                </BLink>
              </li>
            </span>

            <li class="nav-item" @click="clickMenu('profile')" id="profile">
              <BLink class="nav-link fs-15" to="/profiles">Profiles
              </BLink>
            </li>
            <li class="nav-item" @click="clickMenu('organization')">
              <BLink class="nav-link fs-15" to="/organization"> Organization</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('paper')">
              <BLink class="nav-link fs-15" to="/paper">Paper</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('project')">
              <BLink class="nav-link fs-15" to="/project"> Project</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('patent')">
              <BLink class="nav-link fs-15" to="/patent"> Patent</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('outreach')">
              <BLink class="nav-link fs-15" to="/outreach"> Outreach</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('thesis')">
              <BLink class="nav-link fs-15" to="/thesis"> Thesis</BLink>
            </li>
            <li class="nav-item" @click="clickMenu('equipment')">
              <BLink class="nav-link fs-15" to="/equipment"> Equipment</BLink>
            </li>


          </ul>

          <div class="">
            <login_member id="Login"></login_member>
          </div>
        </BCollapse>
      </BContainer>
    </nav>

  </div>
</template>

<script>
import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";


import login_member from './MemberLogin.vue'
import mixpanel from "@/config/mixpanel";
import {
  mapMutations,
  mapState
} from 'vuex'
export default {
  data() {
    return {
      access_token: localStorage.getItem('access_token'),
    }
  },
  components: {
    login_member
  },
  computed: {
    ...mapState({
      autor: state => state.parsing.autor,
      sdg: state => state.parsing.sdg,
      concepts: state => state.parsing.concepts,
      school: state => state.parsing.school,
    }),
  },
  watch: {
    access_token() {

    }
  },
  mounted() {
    // this.startTour();
  },
  methods: {
    ...mapMutations({
      setautor: 'setautor',
      setsSDG: 'setsSDG',
      setcConcepts: 'setcConcepts',
      setcSchool: 'setcSchool'
    }),
    clickMenu(page) {
      mixpanel.track('page_menu_click', {
        'page': page,
      });
      this.setsSDG([]);
      this.setautor([]);
      this.setcConcepts([])
      this.setcSchool([]);
    },
    reloadPage(page) {
      mixpanel.track('page_menu_click', {
        'page': page,
      });
      window.location.reload(); // Melakukan reload halaman
    },
    startTour() {
      if (!this.tour) {
        this.tour = new Shepherd.Tour({
          useModalOverlay: true,
          defaultStepOptions: {
            classes: "shadow-md bg-purple-dark",
            scrollTo: true,
          },
        });

        this.tour.addStep({
          id: "home",
          text: "Selamat datang di Scholar ITB",
          attachTo: { element: "#home", on: "bottom" },
          buttons: [{ text: "Next ->", action: this.tour.next }, { text: "End Tour", action: this.tour.complete }],
        });

        this.tour.addStep({
          id: "profile",
          text: "Find Profile",
          attachTo: { element: "#profile", on: "bottom" },
          buttons: [{ text: "Next ->", action: this.tour.next }, { text: "End Tour", action: this.tour.complete }],
        });
        this.tour.addStep({
          id: "login",
          text: "Login & Register",
          attachTo: { element: "#Login", on: "bottom" },
          buttons: [{ text: "Next ->", action: this.tour.next }, { text: "End Tour", action: this.tour.complete }],
        });


      }

      this.tour.start();
    },

  }
}

</script>

<style></style>