<template>
    <div class="mt-3">
        <footer class=" custom-footer bg-dark py-3 position-absolute bottom-element mt-5 " style="width: 100%;  ">
            <p class="copy-rights mb-1 currentyear text-center ">{{ new Date().getFullYear() }} © Institut Teknologi
                Bandung</p>
        </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.bottom-element {
    position: absolute;
    /* atau position: fixed; */
    bottom: 0;
    /* Menentukan posisi relatif terhadap bottom */
    left: 0;
    /* Opsional, untuk menentukan posisi horizontal */
    z-index: 0;
    /* Memberikan z-index yang lebih rendah daripada elemen di atasnya */
}
</style>