
import { Client }  from 'typesense'; 

const status = localStorage.getItem('status');
let embedKey = localStorage.getItem('api-key');
// let apiKeys ="AmOO9mkkp2RFVHP4MQV2kxVsp5DTmqup";
// let apiKeys = '1LR5Dn4snaxc1rs5RwMCfA25LuxGMvvrtb';
// let apiKeys ="S225ufabXUZdiN9gYQ8FZVQ9HoNzmvAO";
let apiKeys ='kdpEIw8joQMYLhNnKnIz9csIO4HM16lz';
if(status =='embed'){
  apiKeys = embedKey
 

}else{
  apiKeys = apiKeys
}
 
const typesenseClient = new Client({
    nodes: [{ 
            // host: '167.205.195.85',
            host :'arsip.lppm.itb.ac.id',
            //host:'jg3hp2inlfqboeyup-1.a1.typesense.net',
            port: '',
            protocol: 'https' 
          }], 
  apiKey:apiKeys
});
 
export default typesenseClient;