import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MS_CLIENT_ID,  
    authority: "https://login.microsoftonline.com/"+ process.env.VUE_APP_MS_TENANT_ID,
    redirectUri:  process.env.VUE_APP_MS_REDIRECT_URI,  
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export async function initializeMsal() {
  if (!msalInstance || !msalInstance.initialize) {
    console.error("MSAL Instance belum dibuat dengan benar!");
    return;
  }
  await msalInstance.initialize();
}
 


// Display name
// demo_vue_app
// Application (client) ID
// 5cfd1c6e-3f49-4ec1-a5f9-3ae11dab7364
// Object ID
// 937db3a9-8e8a-4605-a13c-3c4c0a79cc2d
// Directory (tenant) ID
// db6e1183-4c65-405c-82ce-7cd53fa6e9dc
// Supported account types